export { }

declare global {
  interface Number {
    format(dec: number, group: number, groupDelimiter: string, decimalChar: string, absolute: boolean): string;
    formatTrunc(dec: number, group: number, groupDelimiter: string, decimalChar: string, absolute: boolean): string;
    whole(group: number, groupDelimiter: string, absolute: boolean): string;
    frac(dec: number, decimalChar: string): string;
  }

  interface Date {
    format(withTime: boolean): string;
    formatTime(): string;
    formatTodayTimeOnly(): string;
    formatAlreadyStartsIn(alreadyGo: string, startsIn: string, now: string): string;
    isToday(): boolean;
    isExpired(to: Date | null): boolean;
    native(withTime: boolean): string;
    diff(other: Date | null, units: string): number;
    diffFormated(other: Date | null): string;
    diffNow(units: string): number;
    addMin(n: number): Date;
    addDay(n: number): Date;
    addMonth(n: number): Date;
    addYear(n: number): Date;
    fromMonthStart(): Date;
    duration(to: Date | null): string;
    mixDateAndTime(date: Date): Date;
    nowUTC(): Date;
    fromUTC(): Date;
    parseTime(value: string | null | undefined): Date;
  }
}

function correctNotation(x: any) {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += (new Array(e + 1)).join('0');
    }
  }
  return x;
}

/* eslint no-extend-native: ["error", { "exceptions": ["Number", "Date"] }] */

Number.prototype.format = function (dec: number, group: number, groupDelimiter: string, decimalChar: string, absolute: boolean): string {
  const value: Number = absolute ? Math.abs(Number(this)) : this;

  const re = '\\d(?=(\\d{' + (group || 3) + '})+' + (dec > 0 ? '\\D' : '$') + ')'
  const num = correctNotation(value.toFixed(Math.max(0, ~~dec)));

  return (decimalChar ? num.replace('.', decimalChar) : num).replace(new RegExp(re, 'g'), '$&' + (groupDelimiter || ','));
}

Number.prototype.formatTrunc = function (dec: number, group: number, groupDelimiter: string, decimalChar: string, absolute: boolean): string {
  const value: string = this.format(dec, group, groupDelimiter, decimalChar, absolute);
  if (value.indexOf(decimalChar) > 0) {
    let res = '';
    let trunc = true;
    for (let i = value.length - 1; i >= 0; i--) {
      if (trunc && value.charAt(i) === '.') {
        trunc = false;
        continue;
      }

      if (trunc && value.charAt(i) !== '0') {
        trunc = false;
      }

      if (!trunc) {
        res = value.charAt(i) + res;
      }
    }

    return res;
  } else {
    return value;
  }
}

Number.prototype.whole = function (group: number, groupDelimiter: string, absolute: boolean): string {
  let num: number = Math.floor(Number(this));
  num = absolute ? Math.abs(num) : num;

  return num.format(0, group, groupDelimiter, '.', absolute);
}

Number.prototype.frac = function (dec: number, decimalChar: string): string {
  const num: Number = Math.abs((Number(this) % 1) * Math.pow(10, dec))
  return decimalChar
    ? num < 10 ? decimalChar + '0' + num.toFixed(0) : decimalChar + num.toFixed(0)
    : num.toFixed(0);
}

Date.prototype.native = function (withTime: boolean): string {
  let s = this.getFullYear() +
    '-' +
    (this.getMonth() < 9 ? '0' + (this.getMonth() + 1) : (this.getMonth() + 1)) +
    '-' +
    (this.getDate() < 10 ? '0' + this.getDate() : this.getDate());

  if (withTime) {
    s = s + 'T' + (this.getHours() < 10 ? '0' + this.getHours() : this.getHours()) +
      ':' +
      (this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes()) +
      ':' +
      (this.getSeconds() < 10 ? '0' + this.getSeconds() : this.getSeconds())
  }

  return s;
}

Date.prototype.format = function (withTime: boolean): string {
  let s = (this.getDate() < 10 ? '0' + this.getDate() : this.getDate()) +
    '.' +
    (this.getMonth() < 9 ? '0' + (this.getMonth() + 1) : (this.getMonth() + 1)) +
    '.' +
    this.getFullYear();

  if (withTime) {
    s = s + ' ' + this.formatTime();
  }

  return s;
}

Date.prototype.formatTime = function (): string {
  return (this.getHours() < 10 ? '0' + this.getHours() : this.getHours()) +
    ':' +
    (this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes());
}

Date.prototype.isToday = function (): boolean {
  const today = new Date()
  return this.getFullYear() === today.getFullYear() && this.getMonth() === today.getMonth() && this.getDate() === today.getDate()
}

Date.prototype.isExpired = function (to: Date | null = null): boolean {
  const toDate = to || new Date();
  return this.getTime() - toDate.getTime() < 0;
}

Date.prototype.formatTodayTimeOnly = function (): string {
  if (this.isToday()) {
    return this.formatTime();
  } else {
    return this.format(true);
  }
}

Date.prototype.formatAlreadyStartsIn = function (alreadyGo: string, startsIn: string, now: string): string {
  if (this.isToday()) {
    const current = Math.trunc(new Date().getTime() / 1000 / 60);
    let diffMin = Math.trunc(this.getTime() / 1000 / 60) - current;
    if (diffMin === 0) {
      return now;
    } else {
      const diffHour = Math.trunc(diffMin / 60);
      let result = '';

      if (diffMin < 0) {
        result += alreadyGo + ' ';
      } else {
        result += startsIn + ' ';
      }

      diffMin = Math.abs(diffMin - (diffHour * 60));

      return result + Math.abs(diffHour) + ':' + (diffMin < 10 ? '0' + diffMin : diffMin);
    }
  } else {
    return this.format(true);
  }
}

Date.prototype.diff = function (other: Date | null, units: string): number {
  let otherMS = 0;
  if (other !== null) {
    otherMS = other.getTime();
  }

  const diff = this.getTime() - otherMS;

  if (units === 's') {
    return Math.trunc(diff / 1000);
  } else if (units === 'm') {
    return Math.trunc(diff / 1000 / 60);
  } else if (units === 'h') {
    return Math.trunc(diff / 1000 / 60 / 60);
  } else if (units === 'd') {
    return Math.trunc(diff / 1000 / 60 / 60 / 24);
  } else {
    return diff;
  }
}

Date.prototype.diffFormated = function (other: Date | null): string {
  const h = this.diff(other, 'h');
  const m = this.diff(other, 'm') - (h * 60);

  return (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m);
}

Date.prototype.diffNow = function (units: string): number {
  return this.diff(new Date(), units);
}

Date.prototype.addMin = function (n: number): Date {
  this.setMinutes(this.getMinutes() + n);
  return this;
}

Date.prototype.addDay = function (n: number): Date {
  this.setDate(this.getDate() + n);
  return this;
}

Date.prototype.addMonth = function (n: number): Date {
  this.setMonth(this.getMonth() + n);
  return this;
}

Date.prototype.addYear = function (n: number): Date {
  this.setFullYear(this.getFullYear() + n);
  return this;
}

Date.prototype.fromMonthStart = function (): Date {
  this.setDate(1);
  return this;
}

Date.prototype.mixDateAndTime = function (date: Date): Date {
  const h = date.getHours();
  const m = date.getMinutes();
  const s = date.getSeconds();
  const result = new Date(this.getTime());
  result.setHours(h, m, s, 0);
  return result;
}

Date.prototype.nowUTC = function (): Date {
  const dt = new Date();
  return new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds()));
}

Date.prototype.fromUTC = function (): Date {
  return new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate(), this.getHours(), this.getMinutes(), this.getSeconds()));
}

Date.prototype.parseTime = function (value: string | null | undefined): Date {
  this.setHours(value && value.length > 1 ? Number(value.substring(0, 2)) : 0);
  this.setMinutes(value && value.length > 3 ? Number(value.substring(3, 5)) : 0);
  this.setSeconds(value && value.length > 6 ? Number(value.substring(6, 8)) : 0);
  this.setMilliseconds(0);
  return this;
};
