import Vue from 'vue'
import { IAppModule, ILink, IView } from '@/common/types'

import AppLoader from '@/common/AppLoader.vue'
import HomePage from '@/common/HomePage.vue'
import NotFoundPage from '@/common/NotFoundPage.vue'
import InitalPage from '@/common/InitalPage.vue'

Vue.component('AppLoader', AppLoader)
Vue.component('HomePage', HomePage)
Vue.component('NotFoundPage', NotFoundPage)
Vue.component('InitalPage', InitalPage)

export default class CommonModule implements IAppModule {
  async init(): Promise<any> {
  }

  async routes(): Promise<Array<ILink>> {
    return []
  }

  async userMenu(): Promise<Array<IView>> {
    return []
  }

  async reports(): Promise<Array<IView>> {
    return []
  }
}
