
import { Vue, Component } from 'vue-property-decorator'

import $app from '@/plugins/modules'
import { Common } from '@/common'

@Component
export default class HomePage extends Vue {
  common = $app.module(Common);

  get activeComponent(): any {
    return this.common.$store.appHome
  }
}
