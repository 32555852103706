/* eslint-disable indent */
import { VuexModule } from 'vuex-module-decorators'
import { AppStates, CommonPages, Access } from './enums'

export interface ILanguage {
    code: string,
    name: string,
    locale: string
}

export interface IView {
    name: string,
    component: any
}

export interface ILink extends IView {
    path: string,
    access: Access | Array<Access>,
    props?: boolean
}

export interface IError {
    code: string;
    message: string;
    details: string;
}

export interface IStopwatch {
    from: number;
    to: number;
    stop(): number;
}

export type GlossaryItemCode = string | number;

export interface IGlossaryItem<T extends GlossaryItemCode> {
    code: T;
    color: string;
    icon: string;
    label: string;
}

export interface IGlossary<T extends GlossaryItemCode, I extends IGlossaryItem<T>> {
    name: string;
    map: Record<T, I>
    items: Array<I>
}

export interface IGlossaryList {
    map: Record<string, IGlossary<any, any>>
    items: Array<IGlossary<any, any>>
}

export type Translate = Record<string, string>;
export type StringMap = Record<string, string>;
export type AccessMap = Record<Access, boolean>;

export type ModuleViews = {
    name: string;
    items: Array<IView>;
};

export class AppError extends Error {
    errorCode: string;
    details: string;

    constructor(code: string, message: string, details: string) {
        super(message)
        this.errorCode = code
        this.details = details;
        Object.setPrototypeOf(this, AppError.prototype)
    }
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface IAppModule {
    init(): Promise<any>;
    routes(): Promise<Array<ILink>>;
    userMenu(): Promise<Array<IView>>;
    reports(): Promise<Array<IView>>;
}

export interface IAppStyle {
    inputOutline: boolean;
    inputDense: boolean;
    inputSolo: boolean;
    inputFilled: boolean;
    inputRounded: boolean;
    dateFormat: string;
    dateTimeFormat: string;
    timeFormat: string;
};

export interface IApplication {
    style: IAppStyle
    dialogHeight: string

    wait(delay: number): Promise<any>;
    log(...args: any[]): void;
    info(...args: any[]): void;
    warn(...args: any): void;
    error(...args: any): void;
    links(path: string, common: CommonPages): ILink;
    page(type: CommonPages): ILink;
    stopwatch(): IStopwatch;
    i18n(key: string): string;
    i18n(key: Translate): string;
    pushError(error?: any): void;
    loading(value: boolean): void;
    getAppState(): AppStates;
    access(access: Access | Array<Access>): boolean;
    clone(obj: any): any;

    glossary<T extends GlossaryItemCode, I extends IGlossaryItem<T>, G extends IGlossary<T, I>>(name: string): G;
    list<T extends GlossaryItemCode, I extends IGlossaryItem<T>>(name: string, order: 'byKey' | 'byValue' | 'none'): Array<I>;
    item<T extends GlossaryItemCode, I extends IGlossaryItem<T>>(name: string, code: T): I | undefined;
    label<T extends GlossaryItemCode>(name: string, code: T): string;

    date(value: any): string;
    datetime(value: any): string;
    time(value: any): string;
    tableOptions(custom: any): any;

    get(url: string, params?: any): any;
    post(url: string, body: any, params?: any, contentType?: string): any;
    put(url: string, body: any, params?: any, contentType?: string): any;
    patch(url: string, body: any, params?: any, contentType?: string): any;
    delete(url: string, params?: any): any;

    request(url: string, method: HttpMethod, params: any, body?: any, contentType?: string): any;
    binary(url: string, method: HttpMethod, params: any, body?: any, contentType?: string, progress?: any): any;

    /* eslint-disable no-use-before-define */
    module<S extends VuexModule, I extends IAppModule, M extends AppModule<S, I>>(moduleClass: AppModuleConstructor<M>): M;
    storage<M extends VuexModule>(moduleClass: VuexModuleConstructor<M>): M;
    /* eslint-enable no-use-before-define */

    _findModuleByName(name: string): any;
}

export type AppModuleConstructor<M> = {
    new(app: IApplication): M;
};

export type VuexModuleConstructor<C> = {
    new(...args: any[]): C;
};

export abstract class AppModule<S extends VuexModule, I extends IAppModule> {
    private $app: IApplication;

    constructor(app: IApplication) {
        this.$app = app
    }

    public abstract get name(): string;
    protected abstract get storeClass(): VuexModuleConstructor<S>;

    public get $module(): I {
        return this.$app._findModuleByName(this.name)
    }

    public get $store(): S {
        return this.$app.storage(this.storeClass)
    }
}

export interface IAppErrors {
    NO_CURING_PLAN: IError
}

export function Errors(): IAppErrors {
    return {
        NO_CURING_PLAN: { code: 'FRT001', message: 'No curing plan found!', details: '' }
    }
}
/* eslint-enable indent */
