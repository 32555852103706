import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import { AppStates, CommonPages } from '@/common/enums'
import { IUser, IUserMenu, IAuthorisation, IAppEnvironmet, IAppInitResponse, IClinic } from './types'
import $app from '@/plugins/modules'
import store from '@/plugins/store'

import CommonStore from '@/common/CommonStore'
const common: CommonStore = getModule(CommonStore)

@Module({ dynamic: true, store, name: 'system' })
export default class SystemStore extends VuexModule {
  _instance: string = '';
  _version: string = '';

  _menu: Array<IUserMenu> = [];

  _clinics: Array<IClinic> = [];
  _clinicSelected: number = -1;

  _user: IUser = {
    id: undefined,
    name: '',
    state: 'b',
    language: 'en',
    fullName: '',
    company: '',
    clinic: '',
    phone: '',
    email: '',
    logo: '',
    background: '',
    toolbarDamage: null,
    taxCode: '',
    profCode: '',
    workNumber: '',
    homePage: '/about',
    rowsPerPage: 10,
    color: undefined,
    companyId: 0,
    companyNVD: '',
    roles: [],
    clinics: [],
    totalIncome: 0,
    totalIncomeNVD: 0,
    totalDebt: 0,
    processing: false
  };

  get getServerInstance(): string {
    return this._instance
  }

  get getServerVersion(): string {
    return this._version
  }

  get getMenu(): Array<IUserMenu> {
    return this._menu
  }

  get getClinics(): Array<IClinic> {
    return this._clinics
  }

  get getClinicSelected(): number {
    return this._clinicSelected
  }

  get getCurrentUser(): IUser {
    return this._user
  }

  // ---------------------------------------------------------------------- MUTATIONS

  @Mutation
  sysLoadUser(data: any) {
    this._user.id = data.id
    this._user.name = data.name
    this._user.fullName = data.fullName
    this._user.company = data.companyName
    this._user.companyId = data.companyId
    this._user.companyNVD = data.companyNVD
    this._user.clinic = data.clinicName
    this._user.phone = data.phone
    this._user.logo = data.logo
    this._user.language = data.language
    this._user.background = data.background
    this._user.toolbarDamage = data.toolbarDamage
    this._user.taxCode = data.taxCode
    this._user.workNumber = data.workNumber
    this._user.homePage = data.homePage || '/about'
    this._user.rowsPerPage = data.rowsPerPage

    this._user.totalIncome = data.totalIncome || 0
    this._user.totalIncomeNVD = data.totalIncomeNVD || 0
    this._user.totalDebt = data.totalDebt || 0

    this._clinicSelected = data.clinicId
  }

  @Mutation
  sysUpdateEnvironment(data: any) {
    this._version = data.serverVersion
    this._instance = data.serverInstance
  }

  @Mutation
  sysSetMenu(menu: Array<IUserMenu>) {
    this._menu = menu
  }

  @Mutation
  sysSelectClinic(value: number) {
    this._clinicSelected = value
  }

  @Mutation
  sysSetClinics(value: Array<IClinic>) {
    this._clinics = value
  }

  // ---------------------------------------------------------------------- ACTIONS

  @Action
  async loadApplication() {
    try {
      if (common.appState === AppStates.APP_LOADING) {
        await this.loadEnvironment()
        common.appSetState(AppStates.APP_LOGON)
      }

      if (common.appState === AppStates.APP_LOGON ||
        common.appState === AppStates.APP_RELOGON
      ) {
        await this.loadUser()
        const link = $app.links(this.getCurrentUser.homePage, CommonPages.NOT_FOUND)
        common.appSetHome(link.component)
        if (this.getCurrentUser.clinic) {
          common.appSetState(AppStates.APP_STARTING)
        } else {
          common.appSetState(AppStates.APP_LOAD_COMPANIES)
        }
      }

      if (common.appState === AppStates.APP_LOAD_COMPANIES) {
        await this.loadClinics()
        common.appSetState(AppStates.APP_SELECT_COMPANY)
      }

      if (common.appState === AppStates.APP_SELECT_COMPANY) {
        let selected
        const clinics = this.getClinics

        if (clinics && clinics.length === 1) {
          selected = clinics[0].id
        } else {
          selected = this.getClinicSelected
        }

        if (selected) {
          await this.selectClinic(selected)
          common.appSetState(AppStates.APP_STARTING)
        }
      }

      if (common.appState === AppStates.APP_STARTING) {
        await this.loadSettings()
        common.appSetState(AppStates.APP_RUN)
      }
    } catch (error) {
      $app.pushError(error)
    }
  }

  @Action({ rawError: true })
  async loadEnvironment() {
    const watch = $app.stopwatch()
    $app.log('Loading system environment ...')

    // get application name and version from package
    const name = process.env.APP_NAME || 'Application'
    const version = process.env.APP_VERSION || '0.1.0'
    common.appSetEnv({ name, version })

    // load current browser language translates
    let language = navigator.language || 'en-US'
    if (language.length > 2) {
      language = language.substring(0, 2)
    }

    await this.changeInitLang(language)

    $app.log('Application loaded in ' + watch.stop() + ' ms')
  }

  @Action({ rawError: true })
  async changeInitLang(language: string) {
    const data: IAppInitResponse = await $app.get('/api/application/settings', { language })
    common.appSetLanguage(data)
  }

  @Action({ rawError: true })
  async changeAppLang(language: string) {
    await $app.patch('/api/security/session', { language })
    location.reload()
  }

  @Action({ rawError: true })
  async loadUser() {
    $app.log('User loading...')
    const watch = $app.stopwatch()

    const data = await $app.get('/api/security/session')

    const access: any = Object.assign({}, common.appAccess)
    for (const accessName in data.access) {
      access[accessName] = data.access[accessName]
    }
    common.appSetAccess(access)

    this.sysLoadUser(data.currentUser)
    this.sysSetMenu(data.menu);

    $app.log('User loaded in ' + watch.stop() + ' ms')
  }

  @Action({ rawError: true })
  async loadClinics() {
    $app.log('Clinics loading...')

    const watch = $app.stopwatch()
    const clinics = await $app.get('/api/security/clinics')
    this.sysSetClinics(clinics)

    $app.log('Clinics loaded in ' + watch.stop() + ' ms')
  }

  @Action({ rawError: true })
  async selectClinic(id: number) {
    $app.log('Selecting clinic ' + id + ' ...')

    const watch = $app.stopwatch()
    const data = await $app.patch('/api/security/session', { clinic: id })
    this.sysLoadUser(data)

    $app.log('Clinic ' + id + ' selected in ' + watch.stop() + ' ms')
  }

  @Action
  async loadSettings() {
    $app.log('Settings loading...')

    const watch = $app.stopwatch()
    const data: IAppEnvironmet = await $app.get('/api/application/settings')

    for (const glossary of data.glossaries) {
      common.appPutGlossary(glossary)
    }
    common.appSetLanguage(data)

    this.sysUpdateEnvironment(data)

    $app.log('Settings loaded in ' + watch.stop() + ' ms')
  }

  @Action({ rawError: true })
  async userLogon(auth: IAuthorisation) {
    await $app.post('/api/security/session', auth)
  }

  @Action({ rawError: true })
  async userSetPass(pwd: string) {
    await $app.patch('/api/security/session', { pwd: pwd })
  }

  @Action({ rawError: true })
  async userLogout() {
    await $app.delete('/api/security/session')
  }
}
