import Vue from 'vue'
import App from './App.vue'
import './plugins/types'
import './plugins/clipboard'
import store from './plugins/store'
import vuetify from './plugins/vuetify'
import router from './plugins/router'
import '@/common'

import $app, { InitApp } from './plugins/modules'

Vue.config.productionTip = false
$app.info('App framework loaded')
InitApp()

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
