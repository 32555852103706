
import { Vue, Component, Watch } from 'vue-property-decorator'
import $router from './plugins/router'

import { AppStates } from './common/enums'
import { ILanguage, IError, ModuleViews, IView } from './common/types'
import { IUserMenu, IUser } from './modules/system/types'

import $app from '@/plugins/modules'
import { Common } from '@/common'
import { System } from '@/modules/system'

@Component
export default class App extends Vue {
  drawer: boolean = true;
  userMenu: boolean = false;
  selectCompany: boolean = false;
  showError: boolean = false;

  common = $app.module(Common);
  system = $app.module(System);

  @Watch('appError')
  onErrorChanged() {
    this.showError = !!this.appError
  }

  get loading(): boolean {
    return this.common.$store.appLoading
  }

  get appVersion(): string {
    return this.common.$store.appVersion
  }

  get appName(): string {
    return this.common.$store.appName
  }

  get appViewTitle(): string {
    return this.common.$store.appViewTitle
  }

  get language(): ILanguage {
    return this.common.$store.appLanguage
  }

  get appError(): IError | undefined {
    return this.common.$store.appError
  }

  get appMenu(): Array<ModuleViews> {
    return this.common.$store.appMenu.filter((item) => item.items.length > 0)
  }

  get appReports(): Array<IView> {
    const reports: Array<IView> = [];
    this.common.$store.appReports.forEach((item) => {
      item.items.forEach(rep => {
        reports.push(rep)
      })
    });
    return reports;
  }

  get appState(): AppStates {
    return this.common.$store.appState
  }

  get languages(): Array<ILanguage> {
    return this.common.$store.appLanguages
  }

  get langIndex(): number {
    return this.languages.findIndex(item => {
      return item.code === this.language.code
    })
  }

  get clinic(): string {
    return this.user.clinic || ''
  }

  get background(): string {
    return this.user.background || ''
  }

  get menuItems(): Array<IUserMenu> {
    return this.system.$store.getMenu || []
  }

  get server(): string {
    return (this.system.$store.getServerInstance || '') + ' ' + (this.system.$store.getServerVersion || '')
  }

  get user(): IUser {
    return this.system.$store.getCurrentUser
  }

  get showReports(): boolean {
    return this.$route.path === '/reports';
  }

  get showInit(): boolean {
    return this.common.$store.appState! < AppStates.APP_RELOGON
  }

  get showLogon(): boolean {
    return this.common.$store.appState! >= AppStates.APP_RELOGON && this.common.$store.appState! < AppStates.APP_RUN
  }

  gotoFeeHistory() {
    this.$router.push('/fees-history-own')
  }

  async changeLang(language: string) {
    try {
      if (language !== this.language.code) {
        $app.loading(true)
        await this.system.$store.changeAppLang(language)
      }
    } catch (error) {
      $app.loading(false)
      $app.pushError(error)
    }
  }

  async logout() {
    $app.loading(true)
    await this.system.$store.userLogout()
    $router.push('/')
    $router.go(0)
  }

  mounted() {
    const timerId = setInterval(() => {
      if (this.common.$store.appState === AppStates.APP_LOADING) {
        clearInterval(timerId)
        this.system.$store.loadApplication()
      }
    }, 100)
  }
}
