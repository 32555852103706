import { Access, AppStates } from '@/common/enums'
import { AccessMap, IError, ILanguage, ModuleViews, StringMap, IGlossaryList, IGlossary, IAppStyle } from '@/common/types'
import { IAppInitResponse } from '@/modules/system/types'
import store from '@/plugins/store'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

const DEF_LANG: ILanguage = { code: 'en', name: 'English', locale: 'en-US' }

@Module({ dynamic: true, store, name: 'common' })
export default class CommonStore extends VuexModule {
  private _appName: string = '';
  private _appVersion: string = '';
  private _appViewTitle: string = '';

  private _appStyle: IAppStyle = {
    inputOutline: true,
    inputDense: false,
    inputSolo: false,
    inputFilled: false,
    inputRounded: false,
    dateFormat: 'DD.MM.YYYY',
    dateTimeFormat: 'DD.MM.YYYY hh:mm',
    timeFormat: 'hh:mm'
  };

  private _appState: AppStates = AppStates.APP_INIT;
  private _appError?: IError = undefined;
  private _appHome: any = undefined;
  private _appLoading: boolean = false;

  private _appMenu: Array<ModuleViews> = [];
  private _reports: Array<ModuleViews> = [];

  private _appLanguages: Array<ILanguage> = [DEF_LANG];
  private _appLanguage: ILanguage = DEF_LANG;

  _glossaries: IGlossaryList = {
    map: {},
    items: []
  };

  private _appAccess: AccessMap = {
    [Access.Any]: true,
    [Access.CompanyManager]: false,
    [Access.DiscountManager]: false,
    [Access.Doctor]: false,
    [Access.FeeManager]: false,
    [Access.FeeViewer]: false,
    [Access.PatientManager]: false,
    [Access.PatientViewer]: false,
    [Access.ReportViewer]: false,
    [Access.SalaryManager]: false,
    [Access.SalaryViewer]: false,
    [Access.SecurityManager]: false,
    [Access.SystemAdministrator]: false,
    [Access.User]: false,
    [Access.VisitCreator]: false,
    [Access.VisitManager]: false,
    [Access.VisitPayer]: false,
    [Access.VisitViewer]: false
  };

  private _appTranslates: StringMap = {
    'global.Loading': 'Loading application...',
    'global.Completing': 'Completing...',
    'global.Error': 'Error',
    'global.Close': 'Close'
  };

  get appName(): string {
    return this._appName
  }

  get appVersion(): string {
    return this._appVersion
  }

  get appState(): AppStates {
    return this._appState
  }

  get appError(): IError | undefined {
    return this._appError
  }

  get appHome(): any {
    return this._appHome
  }

  get appLoading(): boolean {
    return this._appLoading
  }

  get appMenu(): Array<ModuleViews> {
    return this._appMenu
  }

  get appReports(): Array<ModuleViews> {
    return this._reports
  }

  get appLanguages(): Array<ILanguage> {
    return this._appLanguages
  }

  get appLanguage(): ILanguage {
    return this._appLanguage
  }

  get appTranslates(): StringMap {
    return this._appTranslates
  }

  get appAccess(): AccessMap {
    return this._appAccess
  }

  get appViewTitle(): string {
    return this._appViewTitle
  }

  get appGlossaries(): IGlossaryList {
    return this._glossaries
  }

  get appStyle(): IAppStyle {
    return this._appStyle;
  }

  @Mutation
  appSetViewTitle(value: string) {
    this._appViewTitle = value
  }

  @Mutation
  appSetAccess(value: AccessMap) {
    this._appAccess = value
  }

  @Mutation
  appSetState(value: AppStates) {
    this._appState = value
  }

  @Mutation
  appSetHome(value: any) {
    this._appHome = value
  }

  @Mutation
  appSetLoading(value: boolean) {
    this._appLoading = value
  }

  @Mutation
  appSetEnv(data: any) {
    this._appName = data.name
    this._appVersion = data.version
  }

  @Mutation
  appSetLanguage(data: IAppInitResponse) {
    this._appLanguages = data.languages
    this._appTranslates = data.translates

    for (const lng of data.languages) {
      if (lng.code === data.langCode) {
        this._appLanguage = lng
      }
    }
  }

  @Mutation
  appSetError(value: any) {
    let error: IError | undefined

    if (value) {
      error = { code: '', message: '', details: '' }

      if (value.message) {
        error.message = value.message
      } else if (value.errorText) {
        error.message = value.errorText
      }

      if (value.details) {
        error.details = value.details
      } else if (value.errorText) {
        error.details = value.errorDetails
      }

      if (value.errorCode) {
        error.code = value.errorCode
      } else if (value.code) {
        error.code = value.code
      } else if (value.response) {
        error.code = 'HTTP' + value.response.status
      } else {
        error.code = 'SYS'
      }

      if (error.code === 'SYS999') {
        this._appState = AppStates.APP_RELOGON
        error = undefined
      }
    }

    this._appError = error
  }

  @Mutation
  appAddMenu(menu: ModuleViews) {
    this._appMenu.push(menu)
  }

  @Mutation
  appAddReports(menu: ModuleViews) {
    this._reports.push(menu)
  }

  @Mutation
  appPutGlossary(glossary: IGlossary<any, any>) {
    this._glossaries.map[glossary.name] = glossary;

    for (let i = 0; i < this._glossaries.items.length; i++) {
      if (this._glossaries.items[i].name === glossary.name) {
        this._glossaries.items.splice(i, 1, glossary);
        return;
      }
    }

    this._glossaries.items.push(glossary);
  }
}
