import CommonModule from '@/common/CommonModule'
import CommonStore from '@/common/CommonStore'
import { Access, AppStates, CommonPages } from '@/common/enums'
import HomePage from '@/common/HomePage.vue'
import NotFoundPage from '@/common/NotFoundPage.vue'
import { AppError, AppModule, AppModuleConstructor, HttpMethod, IApplication, IAppModule, ILink, IStopwatch, IView, ModuleViews, VuexModuleConstructor, GlossaryItemCode, IGlossaryItem, IGlossary, IAppStyle } from '@/common/types'
import $$modules from '@/modules'
import $router from '@/plugins/router'
import $store from '@/plugins/store'
import axios from 'axios'
import $moment from 'moment'
import Vue from 'vue'
import { getModule, VuexModule } from 'vuex-module-decorators'

declare module 'vue/types/vue' {
  export interface Vue {
    $app: IApplication
  }
}

const commonModule = new CommonModule()
const commonStore = getModule(CommonStore, $store)

class Application implements IApplication {
  $modules: Array<string> = $$modules;
  $routes: Record<string, ILink> = {};
  modulesList: Record<string, any> = {
    'common': commonModule
  };

  constructor(vue: any) {
    vue.use(this)
  }

  install(Vue: any) {
    Vue.prototype.$app = this
  }

  module<S extends VuexModule, I extends IAppModule, M extends AppModule<S, I>>(ModuleClass: AppModuleConstructor<M>): M {
    const wrapper = new ModuleClass(this)
    return wrapper
  }

  storage<M extends VuexModule>(moduleClass: VuexModuleConstructor<M>): M {
    return getModule(moduleClass, $store)
  }

  _findModuleByName(name: string): any {
    return this.modulesList[name]
  }

  async load() {
    this.log('Loading modules...')

    this.$routes['/'] = this.page(CommonPages.HOME)

    const watch = this.stopwatch()
    for (const name of this.$modules) {
      const imports = await import('../modules/' + name)
      await this.loadModule(name, imports)
    }
    this.log('All modules loaded in ' + watch.stop() + ' ms')
    commonStore.appSetState(AppStates.APP_LOADING)
  }

  async loadModule(name: string, imports: any) {
    const ModuleClass = imports.default
    const moduleApp: any = new ModuleClass()

    const module: IAppModule = moduleApp
    const routes: Record<string, ILink> = {}
    const views: Record<string, IView> = {}

    await module.init()
    const moduleRoutes = await module.routes()
    for (const route of moduleRoutes) {
      this.$routes[route.path] = route
    }

    const menu: ModuleViews = { name, items: await module.userMenu() }
    commonStore.appAddMenu(menu)

    const reports: ModuleViews = { name, items: await module.reports() }
    commonStore.appAddReports(reports)

    for (const route of moduleRoutes) {
      $router.addRoute(route)
    }

    this.modulesList[name] = module;

    this.info('Module installed: ' + name)
  }

  get style(): IAppStyle {
    return commonStore.appStyle;
  }

  get dialogHeight(): string {
    return window.innerHeight < 540 ? '' : Math.round(window.innerHeight * 0.7) + 'px';
  }

  date(value: any): string {
    const m = $moment(value, 'YYYY-MM-DD', true);
    if (m.isValid()) {
      return m.format(this.style.dateFormat);
    } else {
      return value ? '' + value : '';
    }
  }

  datetime(value: any): string {
    const m = $moment(value, 'YYYY-MM-DD hh:mm', true);
    if (m.isValid()) {
      return m.format(this.style.dateTimeFormat);
    } else {
      return '' + value;
    }
  }

  time(value: any): string {
    const m = $moment(value, 'YYYY-MM-DD hh:mm', true);
    if (m.isValid()) {
      return m.format('hh:mm');
    } else {
      return '' + value;
    }
  }

  clone(obj: any): any {
    let copy: any;

    // Handle scalar types
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.clone(obj[i]);
      }
      return copy;
    }

    // Handle Object
    /* eslint-disable no-prototype-builtins */
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.clone(obj[attr]);
        }
      }
      return copy;
    }
    /* eslint-enable no-prototype-builtins */

    return obj;
  }

  wait(delay: number): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(() => { resolve('') }, delay)
    })
  }

  log(...args: any[]) {
    if (process.env.NODE_ENV !== 'production') {
      /* eslint-disable no-console */
      console.log(...args)
      /* eslint-enable no-console */
    }
  }

  info(...args: any[]) {
    /* eslint-disable no-console */
    console.info(...args)
    /* eslint-enable no-console */
  }

  warn(...args: any[]) {
    /* eslint-disable no-console */
    console.warn(...args)
    /* eslint-enable no-console */
  }

  error(...args: any[]) {
    /* eslint-disable no-console */
    console.error(...args)
    /* eslint-enable no-console */
  }

  get(url: string, params?: any): any {
    return this.http(url, 'GET', false, params)
  }

  post(url: string, body: any, params?: any, contentType?: string): any {
    return this.http(url, 'POST', false, params, body, contentType)
  }

  put(url: string, body: any, params?: any, contentType?: string): any {
    return this.http(url, 'PUT', false, params, body, contentType)
  }

  patch(url: string, body: any, params?: any, contentType?: string): any {
    return this.http(url, 'PATCH', false, params, body, contentType)
  }

  delete(url: string, params?: any): any {
    return this.http(url, 'DELETE', false, params)
  }

  request(url: string, method: HttpMethod, params: any, body?: any, contentType?: string): any {
    return this.http(url, method, false, params, body, contentType)
  }

  binary(url: string, method: HttpMethod, params: any, body?: any, contentType?: string, progress?: any): any {
    return this.http(url, method, true, params, body, contentType, progress)
  }

  async http(url: string, method: HttpMethod, binary: boolean, params?: any, body?: any, contentType?: string, progress?: any): Promise<any> {
    this.log('HTTP>', method + ' ' + url, 'Params:', params, 'Body:', progress !== undefined ? '[binary]' : body)

    try {
      const response = await axios({
        method: method,
        url: url,
        params: params,
        data: body,
        headers: {
          'Content-Type': contentType === undefined || contentType === null ? 'application/json' : contentType
        },
        onUploadProgress: progress
      })

      this.log('HTTP<', method + ' ' + url, 'Response:', response)

      if (binary || response.data.errorCode === undefined || response.data.errorCode === '000') {
        return response.data
      } else {
        throw new AppError(response.data.errorCode, response.data.errorText, response.data.errorDetails)
      }
    } catch (err) {
      if (err.errorCode === undefined) {
        if (err.response && err.response.data && err.response.data.errorCode) {
          throw new AppError(err.response.data.errorCode, err.response.data.errorText, err.response.data.errorDetails)
        } else {
          throw new AppError('HTTP' + err.response.status, err.message + ' (' + method + ' ' + url + ')', err.response)
        }
      } else {
        throw err
      }
    }
  }

  page(common: CommonPages): ILink {
    switch (common) {
      case CommonPages.NOT_FOUND:
        return { name: 'NotFound', path: '/404', component: NotFoundPage, access: Access.Any }
      default:
        return { name: 'Home', path: '/', component: HomePage, access: Access.Any }
    }
  }

  links(path: string, common: CommonPages): ILink {
    const link = this.$routes[path]
    return link || this.page(common)
  }

  stopwatch(): IStopwatch {
    const init: number = performance.now()
    return {
      from: init,
      to: init,
      stop: function () {
        this.to = performance.now()
        return Math.round(this.to - this.from)
      }
    }
  }

  i18n(key: any): string {
    if (key === null || key === undefined) {
      return '';
    }

    if (typeof key === 'object') {
      const text = key[commonStore.appLanguage.code]
      if (text === undefined || text === null || text === '') {
        return commonStore.appTranslates['system.global.Missed']
      }
      return text
    } else {
      const text = commonStore.appTranslates[key]
      return text === undefined || text === null ? key : text
    }
  }

  glossary<T extends GlossaryItemCode, I extends IGlossaryItem<T>, G extends IGlossary<T, I>>(name: string): G {
    let result = commonStore.appGlossaries.map[name];
    if (!result) {
      result = {
        name,
        map: {},
        items: []
      };
    }
    return <G>result;
  }

  list<T extends GlossaryItemCode, I extends IGlossaryItem<T>>(name: string, order: 'byKey' | 'byValue' | 'none' = 'none'): Array<I> {
    return <Array<I>> this.glossary(name).items.concat().sort((a, b) => {
      if (order === 'byKey') {
        return a.code ? ('' + a.code).localeCompare('' + b.code) : (b.code ? 1 : 0)
      } else if (order === 'byValue') {
        return a.label ? a.label.localeCompare(b.label) : (b.label ? 1 : 0)
      } else {
        return 0
      }
    });
  }

  item<T extends GlossaryItemCode, I extends IGlossaryItem<T>>(name: string, code: T): I | undefined {
    return <I> this.glossary(name).map[code];
  }

  label<T extends GlossaryItemCode>(name: string, code: T): string {
    if (code) {
      const item = this.item(name, code);
      return item === undefined ? '[' + code + ']' : item.label;
    } else {
      return '';
    }
  }

  access(access: Access | Array<Access>): boolean {
    const list = access instanceof Array ? access : [access || Access.Any];

    for (let i = 0, len = list.length; i < len; i++) {
      if (commonStore.appAccess[<Access>list[i]]) {
        return true;
      }
    }

    return false;
  }

  getAppState(): AppStates {
    return commonStore.appState
  }

  pushError(error: any) {
    if (error) {
      this.error(error)
    }
    commonStore.appSetError(error)
  }

  loading(value: boolean) {
    commonStore.appSetLoading(value)
  }

  tableOptions(custom: any): any {
    custom = custom || 25;
    const options = [];

    if (custom !== 10 && custom !== 25 && custom !== 50 && custom !== 100 && custom !== 500) {
      options.push(custom);
    }
    options.push(10);
    options.push(25);
    options.push(50);
    options.push(100);
    options.push(500);

    options.sort(function (a, b) {
      return a - b;
    });
    options.push({ 'text': this.i18n('system.All'), 'value': -1 });

    return {
      itemsPerPageText: this.i18n('system.RowsPerPage'),
      itemsPerPageOptions: options
    };
  }
};

const _app: Application = new Application(Vue)
const app: IApplication = _app

export function InitApp() {
  _app.load()
}

export default app
