import Vue from 'vue'
import Vuex from 'vuex'

const debugMode = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)
const store = new Vuex.Store({
  strict: debugMode
})

export default store
