
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  props: ['progress', 'error', 'name', 'version']
})
export default class InitalPage extends Vue {

}

