export enum AppStates {
    APP_INIT, // First state after page loading, initializing app
    APP_LOADING, // Loading common data from server
    APP_LOGON, // Loading user data
    APP_RELOGON, // Loading user data
    APP_LOAD_COMPANIES, // Loading application data
    APP_SELECT_COMPANY, // Loading application data
    APP_STARTING, // Loading application data
    APP_RUN // Application loaded and run
}

export enum CommonPages {
    HOME = '/',
    NOT_FOUND = '/404'
}

export enum Access {
    Any = 'Any',
    CompanyManager = 'CompanyManager',
    DiscountManager = 'DiscountManager',
    Doctor = 'Doctor',
    FeeManager = 'FeeManager',
    FeeViewer = 'FeeViewer',
    PatientManager = 'PatientManager',
    PatientViewer = 'PatientViewer',
    SalaryManager = 'SalaryManager',
    SalaryViewer = 'SalaryViewer',
    SecurityManager = 'SecurityManager',
    SystemAdministrator = 'SystemAdministrator',
    ReportViewer = 'ReportViewer',
    User = 'User',
    VisitCreator = 'VisitCreator',
    VisitManager = 'VisitManager',
    VisitPayer = 'VisitPayer',
    VisitViewer = 'VisitViewer'
}
