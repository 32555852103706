import { AppModule, VuexModuleConstructor } from '@/common/types'
import CommonStore from '@/common/CommonStore'
import CommonModule from '@/common/CommonModule'

export class Common extends AppModule<CommonStore, CommonModule> {
  public get name(): string {
    return 'common'
  }

  protected get storeClass(): VuexModuleConstructor<CommonStore> {
    return CommonStore
  }
}

export default CommonModule
