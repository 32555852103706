import { AppModule, VuexModuleConstructor } from '@/common/types'
import SystemStore from '@/modules/system/SystemStore'
import SystemModule from '@/modules/system/SystemModule'
import { ISearchRequest } from './types'

export class System extends AppModule<SystemStore, SystemModule> {
  public get name(): string {
    return 'system'
  }

  protected get storeClass(): VuexModuleConstructor<SystemStore> {
    return SystemStore
  }
}

export function createSearch<T extends ISearchRequest>(rowCount: number, defRowCount: number, defOrderBy: string | string[] | null, defOrderDesc: boolean | boolean[] | null, filter: any): T {
  return <T>Object.assign(filter, {
    append: false,
    itemsPerPage: rowCount || defRowCount,
    sortBy: defOrderBy ? (Array.isArray(defOrderBy) ? defOrderBy : [defOrderBy]) : [],
    sortDesc: defOrderDesc ? (Array.isArray(defOrderDesc) ? defOrderDesc : [defOrderDesc]) : [],
    page: 1,
    totals: 0
  });
}

export default SystemModule
