import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Framework } from 'vuetify/types'

declare module 'vue/types/vue' {
  export interface Vue {
    $vuetify: Framework
  }
}

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#7986CB',
        secondary: '#616161',
        accent: '#FFA726',
        error: '#FF5252',
        info: '#BBDEFB',
        success: '#9CCC65',
        warning: '#FDD835',
        disabled: '#666666',
        card_back: '#FFFFFF'
      }
    }
  },
  icons: {
    iconfont: 'fa'
  }
})

export default vuetify;
