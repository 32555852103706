import Vue from 'vue'
import Router, { Route } from 'vue-router'
import HomePage from '@/common/HomePage.vue'
import { AppStates, CommonPages } from '@/common/enums'
import $app from '@/plugins/modules'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    { name: 'Home', path: '/', component: HomePage },
    { name: 'Reports', path: '/reports', component: HomePage }
  ]
})

function route(to: Route, from: Route, next: any, reload: boolean) {
  if (to.path === '/reports') {
    next()
  } else {
    let route = to;
    if (route.matched.length === 0) {
      route = router.resolve(route.fullPath).route;
    }

    let link = $app.page(CommonPages.NOT_FOUND);
    for (let i = 0; i < route.matched.length; i++) {
      link = $app.links(route.matched[i].path, CommonPages.NOT_FOUND);
      if (link.path !== CommonPages.NOT_FOUND) {
        break;
      }
    }

    if (link.path === CommonPages.NOT_FOUND) {
      link = $app.links(to.path, CommonPages.NOT_FOUND)
    }

    if (!$app.access(link.access)) {
      $app.warn('Access denied: "' + to.path + '", redirecting to home page...')
      next('/')
    } else if (link.path === CommonPages.NOT_FOUND) {
      $app.warn('Invalid route: "' + to.path + '", redirecting to home page...')
      next('/')
    } else if (reload) {
      next(route.fullPath)
    } else {
      next()
    }
  }
}

router.beforeEach((to, from, next) => {
  if ($app.getAppState() === AppStates.APP_INIT) {
    const timerId = setInterval(() => {
      if ($app.getAppState() === AppStates.APP_RUN) {
        clearInterval(timerId)
        route(to, from, next, true)
      }
    }, 100)
  } else {
    route(to, from, next, false)
  }
})

export default router
