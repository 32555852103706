import { Access } from '@/common/enums'
import { IAppModule, ILink, IView } from '@/common/types'
import $app from '@/plugins/modules'
import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { createSearch } from '.'
import SystemStore from './SystemStore'
import { IPagination, ISearch, ISearchRequest } from './types'

const $store: SystemStore = getModule(SystemStore)

export default class SystemModule implements IAppModule {
  async init(): Promise<any> {
    Vue.component('v-glossary-item', (await import('./components/ui/inputs/v-glossary-item.vue')).default)
    Vue.component('v-date-search', (await import('./components/ui/inputs/v-date-search.vue')).default)
    Vue.component('v-data-field', (await import('./components/ui/inputs/v-data-field.vue')).default)
    Vue.component('v-data-select', (await import('./components/ui/inputs/v-data-select.vue')).default)
    Vue.component('v-base-action', (await import('./components/ui/actions/v-base-action.vue')).default)
    Vue.component('v-color-selector', (await import('./components/ui/actions/v-color-selector.vue')).default)
    Vue.component('v-file-upload', (await import('./components/ui/inputs/v-file-upload.vue')).default)

    Vue.component('v-base-card', (await import('./components/ui/views/v-base-card.vue')).default)
    Vue.component('v-base-form', (await import('./components/ui/views/v-base-form.vue')).default)
    Vue.component('v-base-view', (await import('./components/ui/views/v-base-view.vue')).default)
    Vue.component('v-base-search', (await import('./components/ui/views/v-base-search.vue')).default)
    Vue.component('v-base-page', (await import('./components/ui/views/v-base-page.vue')).default)

    Vue.component('AppCompanyList', (await import('./components/ui/lists/AppCompanyList.vue')).default)
    Vue.component('v-base-glossary-item', (await import('./components/ui/lists/v-base-glossary-item.vue')).default)

    Vue.component('LogonPage', (await import('./views/LogonPage.vue')).default)
  }

  async routes(): Promise<Array<ILink>> {
    return [

      { name: 'About', path: '/about', component: (await import('./views/About.vue')).default, access: Access.Any }

    ]
  }

  async userMenu(): Promise<Array<IView>> {
    return [

      { name: 'AboutMenu', component: (await import('./components/menu/AboutMenu.vue')).default },
      { name: 'SelectCompanyMenu', component: (await import('./components/menu/SelectCompanyMenu.vue')).default },
      { name: 'PasswordSetMenu', component: (await import('./components/menu/PasswordSetMenu.vue')).default }

    ]
  }

  async reports(): Promise<Array<IView>> {
    return [
    ]
  }

  newSearch<T extends ISearchRequest, I>(search: ISearch<T, I>, filter: any): T {
    return createSearch(
      search.filter.itemsPerPage,
      $store._user.rowsPerPage,
      search.filter.sortBy,
      search.filter.sortDesc,
      filter
    );
  }

  updateSearch<T extends ISearchRequest, I>(search: ISearch<T, I>, pagination: IPagination): ISearch<T, I> {
    const newSearch: ISearch<T, I> = $app.clone(search);

    newSearch.filter.itemsPerPage = pagination.itemsPerPage;
    newSearch.filter.page = pagination.page;
    newSearch.filter.sortBy = pagination.sortBy;
    newSearch.filter.sortDesc = pagination.sortDesc;

    return newSearch;
  }

  async find<T extends ISearchRequest, I>(url: string, search: ISearch<T, I>, pagination: IPagination): Promise<ISearch<T, I>> {
    const payload = this.updateSearch(search, pagination);
    const result = await $app.post(url, payload.filter);
    if (pagination.append) {
      payload.items = payload.items.concat(result.items);
    } else {
      payload.items = result.items;
    }
    payload.filter.totals = result.totals;
    return payload;
  }
}
